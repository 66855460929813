import React from 'react';
import BookContent from "./BookContent";



export default function BookPage() {
    let render = <BookContent/>;
    return (
        render
    );
}
